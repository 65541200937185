<template>
  <div class="map-container">

    <!-- ** map start -->
    <l-map ref="mymap" :zoom="zoom" :center="center" :style="{'height': height}">
      <l-control-fullscreen />
      <l-tile-layer :url="url" />
      <feather-icon icon="MapPinIcon" size="12" class="text-primary reset-map" @click="resetMapZoom()"
        v-if="resetMapZoomOp" />
      <l-geosearch v-if="geosearchOp" :options="geoSearchOptions" class="search-box" />

      <v-locatecontrol />
      <l-marker v-for="(location, i) in locationsOp" :key="i"
                  :lat-lng="latLng(location.locationInfo.locationCoordinates.latitude, location.locationInfo.locationCoordinates.longitude)" v-if="locationsOp">
                <l-tooltip v-if="tooltip" :content="isRtl ? location.locationInfo.titleAr : location.locationInfo.titleEn" />
                <l-popup v-if="popup">
                  <div class="mb-1">
                    <span class="font-weight-bold">
                      <feather-icon icon="MapIcon" size="12" class="text-primary" /> {{ $t('Location ID') }} :
                    </span>
                    <div>{{ location.locationInfo.locationId }}</div>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">
                      <feather-icon icon="CreditCardIcon" size="12" class="text-primary" /> {{ $t('Partner ID Type') }} :
                    </span>
                    <div>{{ location.locationInfo.partnerIdType }}</div>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">
                      <feather-icon icon="UserIcon" size="12" class="text-primary" /> {{ $t('Partner ID') }} :
                    </span>
                    <div>{{ location.locationInfo.partnerId }}</div>
                  </div>
                  <div class="mb-1">
                    <span class="font-weight-bold">
                      <feather-icon icon="MapPinIcon" size="12" class="text-primary" /> {{ $t('Latitude & Longitude') }} :
                    </span>
                    <div>
                          {{ location.locationInfo.locationCoordinates.latitude }}, {{ location.locationInfo.locationCoordinates.longitude }}
            </div>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">
              <feather-icon icon="DollarSignIcon" size="12" class="text-primary" /> {{ $t('Price Per Hour') }} :
            </span>
            <div>
              <b-badge class="font-weight-normal" variant="success" pill>
                {{ location.locationInfo.pricePerHour }} <span class="font-weight-bold">{{ $t('SR') }}</span>
              </b-badge>
            </div>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">
              <feather-icon icon="MonitorIcon" size="12" class="text-primary" /> {{ $t('Screens') }} :
            </span>
            <div>
              <b-badge v-for="(setup, i) in location.locationInfo.setups" :key="i" class="font-weight-normal"
                variant="primary" pill style="margin: 1px">
                {{ setup }}
              </b-badge>
            </div>
          </div>
        </l-popup>
      </l-marker>
    </l-map>
    <!-- End Map Tag-->
  </div>
</template>

<script>

import {
  LMap,
  LTileLayer,
  LCircle,
  LMarker,
  LPopup,
  LIcon,
  LTooltip,
} from "vue2-leaflet";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen";
import LGeosearch from "vue2-leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import Vue2LeafletLocatecontrol from "vue2-leaflet-locatecontrol";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import store from '@/store/'
import { numeric } from "vee-validate/dist/rules";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

class CustomMapProvider {
  async search({ query }) {
    const locationInfo = store.state.app.locations.map(
      (location) => location.locationInfo
    );
    const { isRTL } = store.state.appConfig.layout;
    return locationInfo
      .filter(
        (feature) =>
          feature.locationId.toLowerCase().includes(query.toLowerCase()) ||
          feature.titleEn.toLowerCase().includes(query.toLowerCase()) ||
          feature.titleAr.toLowerCase().includes(query.toLowerCase())
      )
      .map((feature) => ({
        x: feature.locationCoordinates.longitude,
        y: feature.locationCoordinates.latitude,
        label: isRTL ? feature.titleAr : feature.titleEn,
      }));
  }
}

export default {
  props: {
    locationsOp: {
      type: Array,
      required: true,
      default() {
        return { message: '' }
      },
    },
    height: {
      type: String,
      required: true,
      default() { return '600px' },
    },
    center: {
      type: Array,
      required: true,
      default() { return [24.7136, 46.6753] },
    },
    geosearchOp: {
      required: true
    },
    popup: {
      require: true
    },
    resetMapZoomOp: {
      required: true
    },
    tooltip: {
      required: true
    },
    zoom: {
      type: Number,
      required: true,
      default() { return 12 },
    },
  },
  data() {
    return {
      allPartners: "",
      geoSearchOptions: {},
      campaigns: [],
      clients: [],
      setups: [],
      allSetups: [],
      locations: [],
      screens: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    };
  },
  components: {
    LControlFullscreen,
    "v-locatecontrol": Vue2LeafletLocatecontrol,
    LMap,
    LTileLayer,
    LCircle,
    LPopup,
    LIcon,
    LMarker,
    LGeosearch,
    "l-tooltip": LTooltip,

  },
  mounted() {
    console.log(this.locationsOp)
    // this.$nextTick(() => {
    //     this.$refs.map.mapObject.on('geosearch/showlocation', this.onSearch)
    // })
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.mymap.mapObject.invalidateSize();
      }, 100);
    });

    this.geoSearchOptions = {
      provider: new CustomMapProvider(),
      showMarker: false,
      autoClose: true,
      searchLabel: this.$t("Type to Search"),
      updateMap: true,
      keepResult: false,
      animateZoom: true,
    };
  },
  methods: {
    resetMapZoom() {
      const map = this.$refs.map.mapObject
      map.setView(this.center, this.zoom)
    },
    latLng(lat, lng) {
      return L.latLng(lat, lng)
    },
  },
  computed: {
    isRtl() {
      return store.state.appConfig.layout.isRTL
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
